const NextI18Next = require('next-i18next').default;
import detector from 'i18next-browser-languagedetector';
import cookie from 'js-cookie';
import i18next from 'i18next';
const { localeSubpaths } = require('next/config').default().publicRuntimeConfig;
const path = require('path');

i18next.use(detector).init({
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: cookie.get('next-i18next') ? cookie.get('next-i18next') : 'en',
});

module.exports = new NextI18Next({
  defaultLanguage: 'en',
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  otherLanguages: ['ar'],
  localePath: path.resolve('./static/locales'),
});
